const initialState = {
    loading: false,
}

const LocationLoadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOCATIONLOADING':
            return Object.assign( {}, state,{
                loading: action.loading,
            });
        default:
            return state;
    }
}
export default LocationLoadingReducer;