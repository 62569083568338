import {isDesktop, isMobileOnly, isTablet} from "react-device-detect";
import {ThemeProvider} from 'styled-components';
import {GlobalStyle, LIGHT} from './theme'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Docxor from "./pages/desktop/Docxor";
import Home from "./pages/desktop/Home";
import Hospital from "./pages/desktop/Hospital";
import Pharmacy from "./pages/desktop/Pharmacy";
import {useSelector} from 'react-redux';

const App = () => {
  const userLocationAddress = useSelector(state => state.userLocation.address);  

  if(isDesktop){
    return(
      <ThemeProvider theme = {LIGHT}>
        <GlobalStyle/>
        <Router>
          <Routes>
            <Route exact path="/" element={userLocationAddress ? <Home/> : <Docxor/>} />
            <Route exact path="/hospital/:id" element={<Hospital />} />
            <Route exact path="/pharmacy/:id" element={<Pharmacy />} />

          </Routes>
        </Router>
      </ThemeProvider> 
    )
  }

  if(isTablet){
    return(
      <ThemeProvider theme = {LIGHT}>
        <GlobalStyle/>
        <div>tab</div>
      </ThemeProvider> 
    )
  }

  if(isMobileOnly){
    return(
      <ThemeProvider theme = {LIGHT}>
        <GlobalStyle/>
        <div>phone</div>
      </ThemeProvider> 
    )
  }
}
export default App;
