import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {Provider} from 'react-redux';
import store from './store/store'
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

const {REACT_APP_API_URL} = process.env;

const client = new ApolloClient({
  uri: REACT_APP_API_URL,
  cache: new InMemoryCache()
});
const root = ReactDOM.createRoot(document.getElementById('docxor'));

root.render(
  <Provider store = {store}>
    <ApolloProvider client={client}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ApolloProvider>
  </Provider>
);
 // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
