import Styled from 'styled-components';
import { CloseIcon }from '../icons/DocxorIcon'
import ReactDOM from 'react-dom/client';
import {useSelector} from 'react-redux';
import store from '../store/store';
import {sideBar, updateUserLocationCategories, docxorApiLoading} from '../store/action';
import React from 'react';
import $ from 'jquery';
import FilterSkelton from './FilterSkeleton';
import { useQuery } from "@apollo/client";
import { CATEGORY_QUERY} from "../graphql/queries"

const StyledFilter = Styled.div`
    width:755px;
`;
const StyledHeader = Styled.div`
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0 15px 40px -20px rgb(40 44 63 / 15%);
    z-index:1;
`;
const StyledHeaderInner = Styled.div`
    margin: 0;
    padding: 0 353px 0 40px;
`;
const StyledNav = Styled.div`
    margin: auto;
    padding: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
`;
const StyledClose = Styled.div`
    width: 40px;
    height:60px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor:pointer;
`;
const StyledESC = Styled.span`
    font-size: 12px;
    font-weight: 500;
    color: #808080;
`;
const StyledFilterTitle = Styled.div`
    font-size: 24px;
    font-weight: 700;
    color: #363636;
    padding: 0 30px;
`;
const StyledMain = Styled.main`
    min-height: calc(100vh - 158px);
`;
const StyledMainInner = Styled.div`
    margin: 0;
    padding: 10px 353px 10px 40px;
`;
const StyledFilterCriteria = Styled.div`
    padding: 20px 0;
`;
const StyledFilterSubTitle = Styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #363636;
`;
const StyledCategories = Styled.div`
    display:flex;
    flex-wrap: wrap;
`;
const StyledCategory = Styled.label`
    width: 50%;
    padding-top: 15px;
    cursor: pointer;
`;
const StyledCategoryName = Styled.div`
    margin-left: 10px;
    font-size: 16px;
    font-weight: 300;
    color:#363636;
`;
const StyledCategoryContainer = Styled.div`
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    &:hover ${StyledCategoryName} {
        font-weight: 500!important;
      }
`;
const StyledCheckBoxContainer = Styled.div`
    float:left;
`;
const StyledCheckBoxLabel = Styled.label`
    position: relative;
    display: inline-block;
    width: 16px;
    height: 15px;
`;
const StyledPseudoCheckbox = Styled.span`
    cursor: pointer;
    display: block;
    height: 100%;
    border: 1px solid #515151;
    background-color: transparent;
    transition: border .3s;
`;
const StyledCheckBox = Styled.svg`
    height: 16px;
    width: 16px;
    transition: transform .3s cubic-bezier(.23,1,.32,1) 0ms;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    fill: #60b246;
    -ms-transform: scale(0);
    transform: scale(0);
    fill:#1777d1;
`;
const StyledCheckBoxInput = Styled.input`
    display:none;
    &:checked~${StyledPseudoCheckbox}{
        border-color: #1777d1!important;
    }
    &:checked~${StyledCheckBox}{
        transform: scale(1);
    }
`;
const StyledFooter = Styled.div`
    position: sticky;
    background: #fff;
    z-index: 1;
    bottom:0;
    box-shadow: 0 -2px 4px 0 #e9e9eb;
`;
const StyledFooterNav = Styled.div`
    margin: auto;
    padding: 0;
    width: 100%;
    display: flex;
    padding:20px 0px 30px 0px;
    align-items: center;
    justify-content: space-between;
`;
const StyledClearButton =Styled.button`
    padding: 15px 45px;
    text-decoration: none;
    border: 1px solid #515151;
    border-radius:15px;
    font-size: 16px;
    font-weight: 600;
    color: #515151;
    background-color: #fff;
    opacity: ${props => props.disabled ? 0.3 : 1};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    &:hover{
        box-shadow: ${props => props.disabled ? '' : '0 2px 8px #515151'};;
    }
`;
const StyledSubmitButton =Styled.button`
    padding: 15px 80px;
    text-decoration: none;
    cursor: pointer;
    border: none;
    border-radius:15px;
    font-size: 16px;
    font-weight: 600;
    color: #1777d1;
    color: #fff;
    background-color: #1777d1;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%);
    &:hover{
        box-shadow: 0 2px 8px #515151;
    }
`;
const StyledCloseIconContainer = Styled.div`
    color: #363636;
`;
const Filters = () => {
    const isSideBar = useSelector(state => state.sideBar);
    const [categories, setCategories] = React.useState([]);
    const [filterCategories, setFilterCategories] = React.useState([]);
    const userLocation = useSelector(state => state.userLocation);
    const apiLoading = useSelector(state => state.docxorApiLoading.loading);  
    const { loading, error, data } = useQuery(CATEGORY_QUERY);    
    
    const handleUserKeyPress = event => {
        if (event.keyCode === 27) {
            handleCloseOnclick();
        }
    };
    
    React.useEffect(() => {
        if(isSideBar.sideBar === true){
            window.addEventListener("keydown", handleUserKeyPress);
            if (userLocation.categories && userLocation.categories !== '' && userLocation.categories !== null) {
                setFilterCategories(userLocation.categories)
            }
            return () => {
              window.removeEventListener("keydown", handleUserKeyPress);
            };
        } 
    },[]);

    const handleCloseOnclick = () => {
        store.dispatch(sideBar(!isSideBar.sideBar));
        var overlayElement = document.getElementsByClassName('overlay');
            while(overlayElement.length > 0){
                overlayElement[0].parentNode.removeChild(overlayElement[0]);
            }
            document.getElementsByClassName("sidebar-root-container")[0].style.transform = "translateX(1000px)";
            setTimeout(() => {
                const root = ReactDOM.createRoot(document.getElementById('overlay-sidebar-root'));
                root.unmount();
                document.querySelector('body').classList.remove('overlay-enabled');
              }, 250);
    }
    
    const handleCheckboxChange = (e) =>{
        let checkboxChecked = e.target.checked
        if(checkboxChecked === true){
            setFilterCategories(prevState => [...prevState, e.target.value]);
        }else{
            setFilterCategories(prevState =>
                prevState.filter(item => item !== e.target.value)
            );
        }
    }
    
    const handleFilterClear = () =>{
        $('.checkbox').prop('checked', false); // Unchecks it
        setFilterCategories([]);
    }
    const handleFilterSubmit = () => {
        const FilteredCategory = filterCategories && filterCategories.length > 0  ? filterCategories : null;
        store.dispatch(updateUserLocationCategories(FilteredCategory));
        var doxr_userLocation = {"address":userLocation.address, "area":userLocation.area, "city":userLocation.city, "lat":userLocation.lat, "lng":userLocation.lng, "searchType":userLocation.searchType, "categories":FilteredCategory};
        localStorage.setItem("doxr_userLocation", JSON.stringify(doxr_userLocation)); 
        handleCloseOnclick();
    }
    
  
    React.useEffect(() => {
        if (loading) {
            store.dispatch(docxorApiLoading(true));
        }
    }, [loading]);

    React.useEffect(() => {
        if (!loading && !error && data && data.categories) {
            setCategories(data.categories);
            store.dispatch(docxorApiLoading(false));
        }
    }, [data]);
    const listItems = categories.map((category) =>
    <StyledCategory key={category.key}>
        <StyledCategoryContainer>
            <StyledCategoryName>{category.key}</StyledCategoryName>
            <StyledCheckBoxContainer>
                <StyledCheckBoxLabel>
                    <StyledCheckBoxInput 
                        className='checkbox' 
                        type="checkbox" 
                        name="CATEGORY" 
                        value={category.key}
                        checked={filterCategories.includes(category.key)? true : false}
                        onChange={e => handleCheckboxChange(e)}  
                    />
                    <StyledPseudoCheckbox></StyledPseudoCheckbox>
                    <StyledCheckBox width="16" viewBox="0 0 24 24"><path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M9.5,18.25 L20.75,7.43269231 L19,5.75 L9.5,14.8846154 L5,10.5576923 L3.25,12.2403846 L9.5,18.25 Z"></path></StyledCheckBox>
                </StyledCheckBoxLabel>
            </StyledCheckBoxContainer>
        </StyledCategoryContainer>
    </StyledCategory>
  );
    return(
        <StyledFilter>
            <StyledHeader>
                <StyledHeaderInner>
                    <StyledNav>
                        <StyledClose onClick={handleCloseOnclick}>
                            <StyledCloseIconContainer>
                                <CloseIcon size={32}/>
                            </StyledCloseIconContainer>
                            <StyledESC>ESC</StyledESC>
                        </StyledClose > 
                        <StyledFilterTitle>
                            Filters
                        </StyledFilterTitle>
                    </StyledNav>
                </StyledHeaderInner>
            </StyledHeader>
            <StyledMain>
                <StyledMainInner>
                {
                    apiLoading === true 
                    ?
                        <React.Fragment>
                            <FilterSkelton/>
                            <FilterSkelton/>
                        </React.Fragment> 
                    :
                        <StyledFilterCriteria>
                            <StyledFilterSubTitle>Category</StyledFilterSubTitle>
                            <StyledCategories>{listItems}</StyledCategories>
                        </StyledFilterCriteria>
                }
                </StyledMainInner>
            </StyledMain>
            <StyledFooter>
                <StyledHeaderInner>
                    <StyledFooterNav>
                        <StyledClearButton disabled={filterCategories && filterCategories.length? false : true} onClick = {handleFilterClear}>Clear</StyledClearButton>
                        <StyledSubmitButton onClick = {handleFilterSubmit}>Submit</StyledSubmitButton>
                    </StyledFooterNav>
                </StyledHeaderInner>
            </StyledFooter>
        </StyledFilter>
    )
}
export default Filters;