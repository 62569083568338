import React from 'react';
import Styled from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const StyledCard = Styled.div`
`;
const StyleLink = Styled.div`
    text-decoration: none;
    color: inherit;
    display:block;
`;
const StyledCardOuter = Styled.div`
    padding: 20px;
    border: 1px solid #fff;
    contain: content;
`;

const StyledCardInner = Styled.div`
    max-width:254px;
`;
const StyledBody = Styled.div`
    margin-top: 14px;
    display:flex;
`;
const StyledLocation = Styled.div`
    margin-right:20px;
    display:flex;
`;
const StyledCategories = Styled.div`
  margin-left:10px;
`;

const FilterSkelton = () => {
    return(
        <StyledCard>
            <StyleLink>
                <StyledCardOuter>
                    <SkeletonTheme color="#eef0f5" highlightColor="#fafafa">
                        <StyledCardInner>
                            <Skeleton width={280} height={20} />
                            <StyledBody>
                                <StyledLocation>
                                    <Skeleton width={20} height={20} />
                                    <StyledCategories>
                                        <Skeleton width={100} height={20} /> 
                                    </StyledCategories>
                                </StyledLocation>
                                <StyledLocation>
                                    <Skeleton width={20} height={20} />
                                    <StyledCategories>
                                        <Skeleton width={100} height={20} /> 
                                    </StyledCategories>
                                </StyledLocation>
                            </StyledBody>
                        </StyledCardInner>
                    </SkeletonTheme>
                </StyledCardOuter>
            </StyleLink>
        </StyledCard>
    )
}
export default FilterSkelton;