import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {docxorApiLoading} from '../store/action'
import Styled from 'styled-components';
import GridSkeleton from './GridSkeleton';
import InfiniteScroll from "react-infinite-scroll-component";
import Undiscovered from './Undiscovered'
import WentWrong from './WentWrong'
import NoImage from '../asset/noimage.jpeg';
import { LocationPinOutlinedIcon } from '../icons/DocxorIcon';
import { useQuery } from "@apollo/client";
import { NEAR_QUERY, WITHIN_QUERY} from '../graphql/queries'

const StyledRow = Styled.div`
    display:flex;
    justify-content:space-between;
    margin-bottom: 85px;
`;

const StyledCard = Styled.div`
`;
const StyleLink = Styled.a`
    text-decoration: none;
    color: inherit;
    display:block;
`;

const StyledCardOuter = Styled.div`
    padding: 20px;
    border: 1px solid #fff;
    contain: content;
    &:hover {
      border-color: #d3d5df;
      box-shadow: 0 4px 7px 0 rgb(218 220 230 / 60%);
    }
`;

const StyledCardInner = Styled.div`
    max-width:254px;
`;
const StyledImageContainer = Styled.div`
    background: #eef0f5;
    width: 254px;
    height: 160px;
    position:relative;
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(40,44,63,.05);
        content: "";
        background-blend-mode: overlay;
    }
`;
const StyledImage= Styled.img`
    opacity: 1;
    transition: opacity .3s ease-out;
    width: 254px;
    height: 160px;
`;
const StyledBody = Styled.div`
    margin-top: 14px;
`;
const StyledName = Styled.div`
    color:#363636;
    font-size: 17px;
    font-weight: 500;
    word-break: break-word;
    line-height: 1.2rem;
`;

const StyledCategories = Styled.div`
    margin-top:4px;
    display:flex;
`;
const StyledCategory = Styled.div`
    font-size:14px;
    font-weight:300;
    color:#4b4b4b;
`;
const StyledLocation = Styled.div`
    margin-top:5px;
    display: flex;
    align-items: center;
    height: 30px;
`;
const StyledLocationIcon = Styled.span`
    margin-left:-2px;
`;

const StyledLocationName = Styled.span`
    font-size:13px;
    font-weight:300;
    color:#2e2e2e;
    padding-left:2px;
`;
const Styledinvisible = Styled.div`
    width:256px;
    padding:20px;
    visibility:hidden;
`;

const PlaceList = () =>{
    const userLocation = useSelector(state => state.userLocation);
    const apiLoading = useSelector(state => state.docxorApiLoading.loading);  

    const [places, setPlaces] = React.useState([]);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [hasMore, setHasMore] = React.useState(true);
    const [apiError, setApiError] = React.useState(false);
    const page_size = 8;
    const dispatch = useDispatch();

    const QUERY = userLocation.searchType === 'near' ? NEAR_QUERY : WITHIN_QUERY;
    const variables = userLocation.searchType === 'near'
    ? {
        latitude: userLocation.lat, 
        longitude: userLocation.lng, 
        distance: 1, 
        size: page_size, 
        page: pageNumber,
        category: userLocation.categories
      }
    : {
        latitude: userLocation.lat, 
        longitude: userLocation.lng, 
        top_left_latitude: userLocation.top_left_latitude,
        top_left_longitude: userLocation.top_left_longitude,
        bottom_right_latitude: userLocation.bottom_right_latitude,
        bottom_right_longitude: userLocation.bottom_right_longitude,
        size: page_size, 
        page: pageNumber ,
        category: userLocation.categories
      };

    const { loading, error, data } = useQuery(QUERY, {
        variables,
    });
    if (loading) {
        dispatch(docxorApiLoading(true))  
    }

    React.useEffect(() => {
        setPlaces([])
        setPageNumber(1)   
        setHasMore(true)
        setApiError(false)
        window.scrollTo(0, 0)
    }, [userLocation]);

    React.useEffect(() => {
        if (error) {
          setPageNumber(0);
          setHasMore(false);
          setApiError(true);
        }
        if (!loading && !error && data && Object.values(data)[0].healthCenters) {
            dispatch(docxorApiLoading(false))  
            let arr = [...places, ...Object.values(data)[0].healthCenters];
            setPlaces(arr);
            setHasMore(Object.values(data)[0].hasMore);            
        }
    }, [error, data]);

    const changePage = () => { 
        if (data && Object.values(data)[0] && Object.values(data)[0].hasMore != null) {
            Object.values(data)[0].hasMore ? setPageNumber(pageNumber + 1) : setPageNumber(pageNumber)
        }
    }
   
    var placeList = [], row=[], rowLength=4;

    places.forEach((item, index) => {
        row.push(
            <StyledCard key={item.id}>
                <StyleLink href={'/' + item.category + '/' + item.name.replace(/\s+/g, '-') + '-' + item.id}>
                    <StyledCardOuter>
                        <StyledCardInner>
                        <StyledImageContainer>
                            <StyledImage src = {item.image? item.image : NoImage}/>
                        </StyledImageContainer>
                        <StyledBody>
                            <StyledName>{item.name}</StyledName>
                            <StyledCategories>
                            <StyledCategory>
                            {item.category}
                            </StyledCategory>
                            </StyledCategories>
                            <StyledLocation>
                            <StyledLocationIcon>
                                <LocationPinOutlinedIcon size = {14} color={"#1777d1"}/>
                            </StyledLocationIcon>
                            <StyledLocationName>
                            {item.category}
                            </StyledLocationName>
                            </StyledLocation>
                        </StyledBody>
                        </StyledCardInner>
                    </StyledCardOuter>
                </StyleLink>
            </StyledCard>
        );

        if ((row.length === rowLength) || index === places.length - 1) {
            var balance = rowLength-row.length;
            var blankCard=[];
            for(var i = 0; i < balance; i++){
              blankCard.push(<Styledinvisible key={i} ></Styledinvisible>)
            }
            placeList.push(<StyledRow key={index}>{row} {blankCard}</StyledRow>);
            row = [];
          }
    }) 


    return(
        <InfiniteScroll
            dataLength={placeList.length}
            next={changePage}
            hasMore={hasMore}
            loader={<GridSkeleton/>}
        >
            {apiError ? <WentWrong/> : apiLoading === false && placeList.length === 0 ? <Undiscovered/> : placeList}       
        </InfiniteScroll>
    )
}
export default PlaceList;

