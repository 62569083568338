import React from 'react';
import Styled from 'styled-components';
import HealthCenterHeader from '../../parts/desktop/header/HealthCenterHeader'
import GoogleMapComponent from '../../component/GoogleMapComponent';
import { useParams } from 'react-router-dom';
import { useQuery } from "@apollo/client";
import { HEALTHCENTER_QUERY } from '../../graphql/queries';

const StyledBackground = Styled.div`
        display: flex;
        flex-direction: column;
        height:100%;
        min-width:1240px;
        padding: 0;
        margin:0;
`;
const StyledMain = Styled.main`
        display: flex;
        flex-direction: column;
        height: calc(100vh - 80px);
        min-width:100%;
        padding: 0;
        margin:0;
        margin-top:80px;
`;

const Hospital = () => {
  let { id } = useParams();
  const lastHyphenValue = id.split("-").pop();
  const { loading, error, data } = useQuery(HEALTHCENTER_QUERY, {
    variables: { id: parseInt(lastHyphenValue) },
  });
  const [latitude, setLatitude] = React.useState(null);
  const [longitude, setLongitude] = React.useState(null);
  const [title, setTitle] = React.useState('');


  React.useEffect(() => {
    if (!loading && !error && data.healthCenter.id ) {
      console.log(data.healthCenter.location.lat)
      setLatitude(data.healthCenter.location.lat)
      setLongitude(data.healthCenter.location.lon)  
      setTitle(data.healthCenter.name)             
    }
}, [error, data]);
  
    return (
        <React.Fragment>
            <StyledBackground>
                <HealthCenterHeader title={title ? title : 'loading'}/> 
                <StyledMain>
                  {latitude === null ? '' : <GoogleMapComponent latitude={latitude} longitude={longitude} />}
                </StyledMain>
            </StyledBackground>
        </React.Fragment> 
    );
};

export default Hospital; 
