import React from 'react';
import Styled from 'styled-components';
import Logo from '../../../component/Logo'
import store from '../../../store/store'
import {editLocation} from '../../../store/action';
import {useSelector} from 'react-redux';
import { LocationPinOutlinedIcon, DownArrowIcon } from '../../../icons/DocxorIcon';
import EditLocationHeader from '../../../component/EditLocationHeader'

import Navigation from '../../../component/Navigation';

const StyledHeader = Styled.header`
    position:fixed;
    top:0;
    left:0;
    right:0;
    margin:0;
    padding:0;
    width:100%;
    height:auto;
    background:${({ theme }) => theme.primaryWhite}; 
    box-shadow: 0 15px 40px -20px rgb(40 44 63 / 15%);
    z-index:${props => props.$editLocation ? "1001" : "1000"};
`;
const StyledHeaderInner = Styled.div`
    margin:0;
    padding:0 30px;
`;
const StyledNav= Styled.nav`
    margin:auto;
    padding:0;
    min-width:1200px;
    width:100%;
    height:${props => props.$editLocation ? "500px" : "80px"};
    transition: height 0.1s;
    display:flex;
    justify-content: space-between;
`;
const StyledBar = Styled.div`
    // width: 100%;
    display: flex;
    // justify-content: center;
    align-items: center;
`;
const StyledBarInner = Styled.div`
    display: flex;
    align-items: center;
    cursor:pointer;
    max-width:350px;
    height:30px;
`;
const StyledAddressTitle = Styled.span`
    position:relative;
    margin:0 5px;
    font-size:14px;
    font-weight: 700;
    white-space: nowrap;
    color: ${props => props.$isHover === 'true' ? ({ theme }) => theme.primaryBlue : ({ theme }) => theme.primaryGrey};
    &:after {
        content: "";
        position: absolute;
        height: 2px;
        left: 0;
        bottom: -5px;
        width: 100%;
        background: ${props => props.$isHover === 'true' ? ({ theme }) => theme.primaryBlue : ({ theme }) => theme.primaryGrey};
    }
`;
const StyledAddress = Styled.span`
    margin-left:5px;
    font-size:14px;
    font-weight: 300;
    color: ${({ theme }) => theme.secondaryGrey};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
const StyledOverlay = Styled.div`
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255,255,255,0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    overflow: hidden;
`;

const IconContainer = Styled.div`
    padding:5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ArrowIconContainer = Styled.div`
    height: 75%;
    display: flex;
    align-items: flex-end;
    padding: 10px;
`;
const GlobalHeader = () => {
    const userLocation = useSelector(state => state.userLocation);
    const isEditLocation = useSelector(state => state.editLocation);
    // const loading = useSelector(state => state.locationLoading.loading); 
    const isFilters = useSelector(state => state.filters); 
    const [hover, setHover] = React.useState(false); 
   
    const handleHover = () =>{
        setHover(true);
    }
    const hanldeHoverOut = () =>{
        setHover(false);
    }
    const handleOnclick = () =>{
        store.dispatch(editLocation(!isEditLocation.editLocation));
    }
    React.useEffect(() => {
        if(isEditLocation.editLocation === true){
            var x=window.scrollX;
            var y=window.scrollY;
            window.onscroll=function(){window.scrollTo(x, y);};
            return () => window.onscroll=function(){};

        }
        
     }, [isEditLocation.editLocation]);
    return(
        <React.Fragment>
            <StyledHeader $editLocation = {isEditLocation.editLocation}>
                <StyledHeaderInner $filters = {isFilters.filters}>
                    <StyledNav $editLocation = {isEditLocation.editLocation}>
                        {isEditLocation.editLocation ? 
                            <EditLocationHeader/>
                        :
                            <React.Fragment>
                            <Logo/>
                            <StyledBar>
                                <StyledBarInner
                                    onMouseEnter={handleHover}
                                    onMouseLeave={hanldeHoverOut}
                                    onClick={handleOnclick}
                                >
                                    <IconContainer>
                                        <LocationPinOutlinedIcon size={22} color={hover === true ? '#1a78d1' : "#242424"}/>
                                    </IconContainer>
                                    <StyledAddressTitle $isHover = {hover ? "true" : "false"} > 
                                        {userLocation.area ? userLocation.area : userLocation.city}
                                    </StyledAddressTitle>
                                    <StyledAddress>{userLocation.address}</StyledAddress> 
                                    <ArrowIconContainer>
                                        <DownArrowIcon size={15} color={'#1a78d1'}/>
                                    </ArrowIconContainer>
                                </StyledBarInner>
                            </StyledBar> 
                            <Navigation/>
                            </React.Fragment>
                        }
                    </StyledNav>
                </StyledHeaderInner>
            </StyledHeader>
            {isEditLocation.editLocation ? <StyledOverlay onClick={handleOnclick}></StyledOverlay> : ''}    
        </React.Fragment>
        );
};
export default GlobalHeader;