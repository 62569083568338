const initialState = {
    loading: false,
}
const DocxorApiLoadingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'DOCXORAPILOADING':
            return Object.assign( {}, state,{
                loading: action.loading,
            });
        default:
            return state;
    }
}
export default DocxorApiLoadingReducer;
