import React from "react";
import Styled from 'styled-components';
import Header from '../../parts/desktop/header/DocxorHeader';
import Main from '../../parts/desktop/main/DocxorMain'; 
import Footer from '../../parts/desktop/footer/DocxorFooter';   

const StyledBackground = Styled.div`
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 1240px;
`;

const Docxor = () => {
    return (
        <React.Fragment>
            <StyledBackground>
                <Header />
                <Main />
                <Footer />
            </StyledBackground>
        </React.Fragment>
    )
}

export default Docxor;