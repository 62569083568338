import React from 'react';
import Styled from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'

const StyledCard = Styled.div`
`;
const StyleLink = Styled.div`
    text-decoration: none;
    color: inherit;
    display:block;
`;
const StyledCardOuter = Styled.div`
    padding: 20px;
    border: 1px solid #fff;
    contain: content;
`;

const StyledCardInner = Styled.div`
    max-width:254px;
`;
const StyledBody = Styled.div`
    margin-top: 14px;
`;
const StyledCategories = Styled.div`
  margin-top:4px;
  display:flex;
`;
const StyledLocation = Styled.div`
  margin-top:5px;
  display: flex;
  align-items: center;
  height: 30px;
`;
const CardSkeleton = () => {
    return(
        <StyledCard>
            <StyleLink>
                <StyledCardOuter>
                    <SkeletonTheme color="#eef0f5" highlightColor="#fafafa">
                        <StyledCardInner>
                            <Skeleton width={254} height={160} />
                            <StyledBody>
                                <Skeleton width={200} height={19} />
                                <StyledCategories>
                                    <Skeleton width={254} height={17} /> 
                                </StyledCategories>
                                <StyledLocation>
                                    <Skeleton width={93} height={16} /> 
                                </StyledLocation>
                            </StyledBody>
                        </StyledCardInner>
                    </SkeletonTheme>
                </StyledCardOuter>
            </StyleLink>
        </StyledCard>
    )
}
export default CardSkeleton;