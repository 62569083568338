const initialState = {
    editLocation: false,
}
const EditLocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'EDITLOCATION':
            return Object.assign( {}, state,{
                editLocation: action.editLocation,
            });
        default:
            return state;
    }
}
export default EditLocationReducer;
