import React from 'react';
import Styled from 'styled-components';
import PlaceList from '../../../component/PlaceList';
const StyledMain = Styled.main`
  padding: 80px 0 0 0;
  margin:0;
  height:100%;
  width:100%;
`;

const StyledMainInner = Styled.div`
  margin:0;
  padding:0 20px;
`;

const StyledMainContainer = Styled.div`
  margin:auto;
  padding:0;
  min-width:1240px;
  max-width:1240px;
  height:auto;
`;

const AssetContainer = Styled.div`
  padding-top:80px;
  min-height: calc(100vh - 150px);
`;


const GlobalMain = () => {
    return(
        <StyledMain>
          <StyledMainInner>
            <StyledMainContainer>
              <AssetContainer>
                <PlaceList/>
              </AssetContainer>
            </StyledMainContainer>
          </StyledMainInner>
        </StyledMain>
    );
}

export default GlobalMain;