import {combineReducers} from 'redux';
import LocationLoadingReducer from './LocationLoading'
import UserLocationReducer from './UserLocation'
import EditLocationReducer from './EditLocation'
import DocxorApiLoadingReducer from './DocxorApiLoading'
import SideBarReducer from './SideBar'
import FiltersReducer from './Filters'

const rootReducer = combineReducers({
    locationLoading: LocationLoadingReducer,
    userLocation: UserLocationReducer,
    editLocation: EditLocationReducer,
    docxorApiLoading: DocxorApiLoadingReducer,
    sideBar: SideBarReducer,
    filters: FiltersReducer
});

export default rootReducer;