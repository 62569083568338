import React from 'react';
import Styled from 'styled-components';
import { GpsIcon, CircularLoadingIcon } from '../icons/DocxorIcon';
import { locationLoading } from  '../store/action'
import store from '../store/store'
import {useSelector} from 'react-redux';
import { GoogleCordsDetails } from '../api/GoogleApi'
import { StructuredAddressDetails, addressSelected } from "../helpers/AddressHelper"

const ButtonContainer= Styled.button`
    margin:0;
    padding:0;
    width: ${props => props.$btnType === 'editLocation' ? '100%' : '238px'};
    height: ${props => props.$btnType === 'editLocation' ? '64px' : '49px'};
    border-radius: 10px;
    background-color:#1777d1;
    color:#fff;
    text-transform: capitalize;
    display:flex;
    align-items:center;
    justify-content: center;
    cursor:pointer;
    outline:0;
    border:none;
    &:hover {
        background-color: #1777d1;
        opacity:0.8;
    }
    &:disabled {
        color:#fff;
        background-color: #1777d1;
        opacity:0.8;
    }
`;
const IconContainer = Styled.div`
    padding:10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;




const LocateMe = (props) => {
    const loading = useSelector(state => state.locationLoading.loading);  

    const handleOnclick = (props) => {
        store.dispatch(locationLoading(true));
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
              (pos) => fetchCoordsInfo(props, pos),
              (err) => permissionDenied(err)
            );
        }else{
            store.dispatch(locationLoading(false));
            alert('GPS device is not available in your machine')
        }
    }

    const permissionDenied = (error) => {  
        const errorMessage = error.code === 1 ? 'You have blocked Docxor from tracking your location. To use this, change your location settings in browser.' :
        error.code === 2 ?'Unable to track the location' :
        error.code === 3 ?'Time our please try again' : 'Please try again';
        
        store.dispatch(locationLoading(false));
        alert(errorMessage)
    };
      
    const fetchCoordsInfo = async (props, position) => {
        try {
            const cordsDetails = await GoogleCordsDetails(position);

            if (cordsDetails && cordsDetails.length > 0) {
                const addressInfo= await StructuredAddressDetails(cordsDetails);
                if (addressInfo != null) {
                    addressSelected(addressInfo)
                    props.onFetchCoordsInfo()
                  } else {
                    alert("choose a city or a place")
                  }
            }
        } catch (error) {
            store.dispatch(locationLoading(false));
            console.error('Error in fetchCoordsInfo:', error);
        }
    }

    return(
        <ButtonContainer onClick = {()=>handleOnclick(props)} disabled={loading} $btnType = {props.btnType ? props.btnType : 'primary'}>
         {
            loading ? <CircularLoadingIcon size = {60} color={"#ffffff"}/>
            :
            <React.Fragment>
                <IconContainer>
                    <GpsIcon size = {20} color={"#ffffff"}/>
                </IconContainer>
                {props.title}
            </React.Fragment>
         }
        </ButtonContainer>
    );
};
export default LocateMe;