import React from 'react';
import Styled from 'styled-components';
import CoverText from '../../../component/CoverText';
import SearchLocation from '../../../component/SearchLocation';

const StyledMain = Styled.main`
    margin:0;
    padding:0;
    width:100%;
    height:auto;
`;

const StyledMainOuter = Styled.div`
    margin:auto;
    min-height:600px;
    height:calc(100vh - 185px);
    max-width:1200px;
`;

const StyledMainInner = Styled.div`
    margin:auto;
    width:650px;
    position:relative;
    top:80px;
`;


const DocxorMain = () => {
    return(
        <StyledMain>
            <StyledMainOuter>
                <StyledMainInner>
                    <CoverText/>                
                    <SearchLocation/>
                </StyledMainInner>
            </StyledMainOuter>
        </StyledMain>
    );
};
export default DocxorMain;