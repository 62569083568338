import React from 'react';
import Styled from 'styled-components';
import GlobalHeader from '../../parts/desktop/header/GlobalHeader'
import Main from '../../parts/desktop/main/GlobalMain'
const StyledBackground = Styled.div`
        display: flex;
        flex-direction: column;
        height:100%;
        min-width:1240px;
        padding: 0;
        margin:0;
`;
const Home = () => {
    return (
        <React.Fragment>
            <StyledBackground>
                <GlobalHeader/> 
                <Main/>
            </StyledBackground>
        </React.Fragment> 
    )
}
export default Home;