import React from 'react';
import Styled from 'styled-components';
// import FacebookIcon from '@material-ui/icons/Facebook';  
// import TwitterIcon from '@material-ui/icons/Twitter';  
// import InstagramIcon from '@material-ui/icons/Instagram';  

const StyledFooter = Styled.footer`
    margin:0;
    padding:0;
    width:100%;
    height:auto;
`;

const StyledFooterInner = Styled.div`
    margin:0;
    padding:0 30px;
`;

const StyledNav= Styled.nav`
    margin:auto;
    padding:30px 0px;
    width:100%;
    display:flex;
`;

const Styledh5= Styled.h5`
    margin:0;
    padding:0;
    line-height: 1.67;
`;

const StyledCopyright= Styled.div`
    display:flex;
    justify-content: start;
    width:100%;
`;

const StyledSocial= Styled.div`
    display:flex;
    justify-content: flex-end;
    width:100%;
`;

// const StyledSocialLink= Styled.a`
// padding:0 20px 0 20px;
// color:#515151;
// -webkit-transition: all 0.3s ease-in-out;
// -moz-transition: all 0.3s ease-in-out;
// -ms-transition: all 0.3s ease-in-out;
// -o-transition: all 0.3s ease-in-out;
// transition: all 0.3s ease-in-out;
// &:hover {
//     color:#222;
//     -webkit-transform: scale(1.3);
// 	-moz-transform: scale(1.3);
// 	-ms-transform: scale(1.3);
// 	-o-transform: scale(1.3);
// 	transform: scale(1.3); 
// }
// `;

const DocxorFooter = () => {
    return(
           <StyledFooter>
               <StyledFooterInner>
                <StyledNav>
                    <StyledCopyright>
                        <Styledh5>&copy; {new Date().getFullYear()} Docxor</Styledh5>
                    </StyledCopyright>
                    <StyledSocial>
                        {/* <StyledSocialLink href = "https://www.facebook.com/" target="_blank"><FacebookIcon/></StyledSocialLink>
                        <StyledSocialLink href = "https://twitter.com/" target="_blank"><TwitterIcon/></StyledSocialLink>
                        <StyledSocialLink href = "https://www.instagram.com/" target="_blank"><InstagramIcon/></StyledSocialLink> */}
                    </StyledSocial>
                </StyledNav>
               </StyledFooterInner>
            </StyledFooter>
        );
};
export default DocxorFooter;