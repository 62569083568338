import Styled from 'styled-components';
import AmbulancePunctured from '../asset/ambulancepunctured.png'

const StyledDontServe = Styled.div`
    width: 100%;
    margin: 0;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const StyledExploreImage = Styled.div`
    width: 600px;
    height: 400px;
    background-image: url(${AmbulancePunctured});
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
`;
const StyledTitle = Styled.div`
    margin-top: 24px;
    font-size: 20px;
    font-weight: 700;
    color: #242424;
`;
const StyledSubTitle = Styled.div`
    margin-top: 10px;
    font-size:14px;
    font-weight:300px;
    color: #363636;
`;

const WentWrong = () => {
    return(
        <StyledDontServe>
            <StyledExploreImage></StyledExploreImage>
            <StyledTitle>Sorry, Something went wrong !!!</StyledTitle>
            <StyledSubTitle>Please try again</StyledSubTitle>
        </StyledDontServe>
    )
}
export default WentWrong;