import React from 'react';
import { ReactComponent as LocationPin } from './images/locationpin.svg';
import { ReactComponent as LocationPinOutlined } from './images/locationpinoutlined.svg';
import { ReactComponent as Gps } from './images/gps.svg';
import { ReactComponent as CircularLoading } from './images/circularloading.svg';
import { ReactComponent as DownArrow } from './images/downarrow.svg';
import { ReactComponent as Filter } from './images/filter.svg';
import { ReactComponent as Close } from './images/close.svg';

export const LocationPinIcon = (props) => {
  const svgStyle = {
    fill: props.color ? props.color : '#000000', 
  };

  return (
    <React.Fragment>
      <LocationPin style={svgStyle} width={props.size? props.size : 10} height={props.size? props.size : 10} />
    </React.Fragment>
  );
};

export const LocationPinOutlinedIcon = (props) => {
  const svgStyle = {
    fill: props.color ? props.color : '#000000', 
  };

  return (
    <React.Fragment>
      <LocationPinOutlined style={svgStyle} width={props.size? props.size : 10} height={props.size? props.size : 10} />
    </React.Fragment>
  );
};

export const GpsIcon = (props) => {
  const svgStyle = {
    fill: props.color ? props.color : '#000000', 
  };

  return (
    <React.Fragment>
      <Gps style={svgStyle} width={props.size? props.size : 10} height={props.size? props.size : 10} />
    </React.Fragment>
  );
};

export const CircularLoadingIcon = (props) => {
  const svgStyle = {
    fill: props.color ? props.color : '#000000', 
  };

  return (
    <React.Fragment>
      <CircularLoading style={svgStyle} width={props.size? props.size : 10} height={props.size? props.size : 10} />
    </React.Fragment>
  );
};
export const DownArrowIcon = (props) => {
  const svgStyle = {
    fill: props.color ? props.color : '#000000', 
  };

  return (
    <React.Fragment>
      <DownArrow style={svgStyle} width={props.size? props.size : 10} height={props.size? props.size : 10} />
    </React.Fragment>
  );
};
export const FilterIcon = (props) => {
  const svgStyle = {
    fill: props.color ? props.color : '#000000', 
  };

  return (
    <React.Fragment>
      <Filter style={svgStyle} width={props.size? props.size : 10} height={props.size? props.size : 10} />
    </React.Fragment>
  );
};

export const CloseIcon = (props) => {
  const svgStyle = {
    fill: props.color ? props.color : '#000000', 
  };

  return (
    <React.Fragment>
      <Close style={svgStyle} width={props.size? props.size : 10} height={props.size? props.size : 10} />
    </React.Fragment>
  );
};