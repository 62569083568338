import React from 'react';
import Styled from 'styled-components';
import {useSelector} from 'react-redux';
import store from '../store/store'
import {editLocation} from '../store/action';
import Logo from './Logo'
import EditLocation from './EditLocation'
import { LocationPinOutlinedIcon, CloseIcon }from '../icons/DocxorIcon'

const EditLocationContainer = Styled.div`
    width:100%;
    height:100%;
    display:flex;
    flex-direction: column;
`;
const StyledCloseContainer = Styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const StyledClose = Styled.div`
    width: 40px;
    height:80px;
    display:flex;
    flex-direction: column;
    align-items: center;
    cursor:pointer;
`;
const StyledCloseIconContainer = Styled.div`
    width:40px !important;
    height:40px !important;
    padding-top:15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #808080;
`;
const StyledESC = Styled.span`
    font-size: 14px;
    font-weight: 700;
    color: #808080;
    padding:5px 0;
`;
const StyledLocationBarContainer = Styled.div`
    margin:0 auto;
`;
const CurrentAddress = Styled.div`
    margin-left: -160px;
    width: 100%;
    max-width:650px;
    display:flex;
    justify-content:center;
`;
const IconContainer = Styled.div`
    padding:5px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const StyledAddress = Styled.span`
    margin-left:5px;
    font-size:14px;
    font-weight: 300;
    color: #a7a7a7;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
`;

const EditLocationHeader = () =>{
    const isEditLocation = useSelector(state => state.editLocation);
    const userLocation = useSelector(state => state.userLocation);

    const handleOnclick = () =>{
        store.dispatch(editLocation(!isEditLocation.editLocation));
    }
    const handleUserKeyPress = event => {
        if (event.keyCode === 27) {
            store.dispatch(editLocation(!isEditLocation.editLocation));
        }
    };
    React.useEffect(() => {
        if(isEditLocation.editLocation === true){
            window.addEventListener("keydown", handleUserKeyPress);
            return () => {
              window.removeEventListener("keydown", handleUserKeyPress);
            };
        }
    });
return (
    <EditLocationContainer>
        <StyledCloseContainer >   
            <Logo/>
            <CurrentAddress>
                <IconContainer>
                    <LocationPinOutlinedIcon size={22} color={"#a7a7a7"}/>
                </IconContainer>
                <StyledAddress>{userLocation.address}</StyledAddress>

            </CurrentAddress>
            <StyledClose onClick={handleOnclick}>
                <StyledCloseIconContainer>
                    <CloseIcon size={32}/>
                </StyledCloseIconContainer>
                <StyledESC>ESC</StyledESC>
            </StyledClose >                         
        </StyledCloseContainer> 
        <StyledLocationBarContainer>
            <EditLocation/>
        </StyledLocationBarContainer>
    </EditLocationContainer>
)
}
export default EditLocationHeader;