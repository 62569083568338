import React from 'react';
import ReactDOM from 'react-dom/client';
import Styled, { keyframes } from 'styled-components';
import {useSelector, Provider} from 'react-redux';
import store from '../store/store';
import {sideBar} from '../store/action';
import { FilterIcon } from '../icons/DocxorIcon';
import Filterspage from './Filters'
import { slideInRight} from 'react-animations';
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";

const {REACT_APP_API_URL} = process.env;

const client = new ApolloClient({
    uri: REACT_APP_API_URL,
    cache: new InMemoryCache()
});

const sideBarAnimation = keyframes`${slideInRight}`;

const StyledGroup = Styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
    // width:100%;
    // justify-content: space-evenly;
`;
const StyledList = Styled.li`
    display: flex;
    align-items: center;
    cursor:pointer;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    color: #242424;
    &:hover{
        color: #1777d1;
    }
`;
const StyledListIcon = Styled.div`
    margin-right:10px;
`;
const StyledFilters = Styled.div`
    position: fixed;
    top: 0;
    right:0;
    left:auto;
    height: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 10001;
    animation: 0.3s ${sideBarAnimation}; 
    transition: transform .3s ease-out;
`;

const Navigation = () => {
    // const isFilters = useSelector(state => state.filters);
    const isSideBar = useSelector(state => state.sideBar);

    const FilterBarContainer = () => {
        return(
            <StyledFilters className='sidebar-root-container'>
                <Filterspage/>
            </StyledFilters>
        )
    }

    let root;
    const handleFilersClick = () => {
        store.dispatch(sideBar(!isSideBar.sideBar));
        
        // Create overlay element
        var overlayElement = document.createElement('div');
        overlayElement.setAttribute("class", "overlay");
        document.body.appendChild(overlayElement);
        overlayElement.addEventListener('click', handleOverlayClick);
        document.body.classList.add('overlay-enabled');
        
        // Create or retrieve overlay-sidebar-root
        var overlaySidebarRoot = document.getElementById('overlay-sidebar-root');
        if (overlaySidebarRoot === null) {
            var sideBarRoot = document.createElement('div');
            sideBarRoot.setAttribute("id", "overlay-sidebar-root");
            document.body.appendChild(sideBarRoot);
            overlaySidebarRoot = sideBarRoot;
        }
        
        // Create or use existing root instance
        if (!root) {
            root = ReactDOM.createRoot(overlaySidebarRoot);
        }
        
        // Render component
        root.render(
            <Provider store={store}>
                <ApolloProvider client={client}>
                    <FilterBarContainer />
                </ApolloProvider>
            </Provider>
        );
    };
      
    const handleOverlayClick = () => {
        store.dispatch(sideBar(false));
      
        // Remove overlay elements
        var overlayElements = document.getElementsByClassName('overlay');
        while (overlayElements.length > 0) {
          overlayElements[0].parentNode.removeChild(overlayElements[0]);
        }
      
        // Animate sidebar off-screen
        document.getElementsByClassName("sidebar-root-container")[0].style.transform = "translateX(1000px)";
      
        // Set a timeout to unmount the component after the animation duration
        setTimeout(() => {
          if (root) {
            root.unmount();
            root = null;
          }
      
          // Remove the overlay-enabled class from the body
          document.body.classList.remove('overlay-enabled');
        }, 250);
    };
    
    return(
        <StyledGroup>
            {/* <StyledList disabled={true}onClick={handleSearchClick}>
                <StyledListIcon><SearchOutlinedIcon/></StyledListIcon>
                <div>Search</div>
            </StyledList> */}
            <StyledList onClick={handleFilersClick}>
                <StyledListIcon >
                    <FilterIcon size={23} color={"#242424"}/>
                </StyledListIcon>
                <div>Filters</div>
            </StyledList>
            {/* <StyledList onClick={handleHelpClick}>
                <StyledListIcon><LocalHospitalIcon/></StyledListIcon>
                <div>Help</div>
            </StyledList>
            <StyledList onClick={handleSigninClick}>
                <StyledListIcon><PermIdentityOutlinedIcon/></StyledListIcon>
                <div>Sign in</div>
            </StyledList>
            <StyledList disabled={true} onClick={handleCartClick}>
                <StyledListIcon><LocalMallOutlinedIcon/></StyledListIcon>
                <div>Cart</div>
            </StyledList> */}
        </StyledGroup>
    );
}
export default Navigation;