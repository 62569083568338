const initialState = {
    filters: false,
}
const FiltersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FILTERS':
            return Object.assign( {}, state,{
                filters: action.filters,
            });
        default:
            return state;
    }
}
export default FiltersReducer;
