import React from 'react';
import { useSelector } from 'react-redux';
import Styled from 'styled-components';
import TextTransition, { presets } from "react-text-transition";

const TEXTS = [
  {name: "Hospital", color:"#34a853"},
  {name: "Pharmacy", color:"#1777d1"},
  {name: "Laboratory", color:"#cb0701"},
  {name: "Scan center", color:"#6a0499"},
//   {name: "Surgical shop", color:"#FB9901"},
];

const Styledtitle = Styled.h1`
  margin:0;
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: 1px;
`;

const HealthServices = () => {
  const loading = useSelector(state => state.locationLoading.loading);  
  const [index, setIndex] = React.useState(0);
  
  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index + 1),
      3000
    );
    return () => clearTimeout(intervalId);
  }, [index]);

  return(
      <React.Fragment>
        <Styledtitle>
          <TextTransition 
            springConfig={presets.gentle}
            style = {{
              color:TEXTS[loading?0:index % TEXTS.length]["color"]
            }}
            direction ="up"
          >
            {TEXTS[loading?0:index % TEXTS.length]["name"]}
          </TextTransition>
        </Styledtitle>
      </React.Fragment>
  );
};
export default HealthServices;