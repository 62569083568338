import Styled from 'styled-components';
import {editLocation} from '../store/action';
import {useSelector, useDispatch} from 'react-redux';
import AmbulanceSearch from '../asset/ambulancesearch.png'

const ButtonContainer= Styled.button`
    margin-top:30px;
    padding:0;
    width: 238px;
    height: 50px;
    border-radius: 10px;
    background-color:#34a853;
    color:#fff;
    display:flex;
    align-items:center;
    justify-content: center;
    cursor:pointer;
    outline:0;
    border:none;
    text-transform: uppercase;
    font-size: 15px;
    &:hover {
        background-color: #34a853;
        opacity:0.8;
    }
    &:disabled {
        color:#fff;
        background-color: #34a853;
        opacity:0.8;
    }
`;
const StyledDontServe = Styled.div`
    width: 100%;
    margin: 0;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const StyledExploreImage = Styled.div`
    width: 600px;
    height: 400px;
    background-image: url(${AmbulanceSearch});
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
`;
const StyledTitle = Styled.div`
    margin-top: 24px;
    font-size: 20px;
    font-weight: 700;
    color: #242424;
`;
const StyledSubTitle = Styled.div`
    margin-top: 10px;
    font-size:14px;
    font-weight:300px;
    color: #363636;
`;

const Undiscovered = () => {
    const isEditLocation = useSelector(state => state.editLocation);
    const dispatch = useDispatch();

    const handleOnclick = () =>{
        dispatch(editLocation(!isEditLocation.editLocation));
    }

    return(
        <StyledDontServe>
            <StyledExploreImage></StyledExploreImage>
            <StyledTitle>Sorry, we will start serving this location soon !!!</StyledTitle>
            <StyledSubTitle>Please try with different location</StyledSubTitle>
            <ButtonContainer onClick={handleOnclick}>Change Location</ButtonContainer>
        </StyledDontServe>
    )
}
export default Undiscovered;