import React from 'react';
import Styled from 'styled-components';
import CardSkeleton from './CardSkeleton'

const StyledRow = Styled.div`
    display:flex;
    justify-content:space-between;
    margin-bottom: 85px;
`;

const GridSkeleton = () => {
    return(
        <React.Fragment>
            <StyledRow>
                <CardSkeleton/>
                <CardSkeleton/>
                <CardSkeleton/>
                <CardSkeleton/>
            </StyledRow>
            <StyledRow>
                <CardSkeleton/>
                <CardSkeleton/>
                <CardSkeleton/>
                <CardSkeleton/>
            </StyledRow>
        </React.Fragment>

    )
}
export default GridSkeleton;