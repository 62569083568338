import AxiosGoogle from "./axios/AxiosGoogle";

export const GooglePlaceDetails = (place_id) => {
    return new Promise((resolve, reject) => {
        const params = {
          place_id:place_id
        }
        AxiosGoogle().get('geocode/json',{params})
         .then(response => {
          resolve(response.data.results)
        }).catch(error => {
          reject(error);
        });
    })
};

export const GoogleCordsDetails = (position) => {
    return new Promise((resolve, reject) => {
        const params = {
        latlng: `${position.coords.latitude},${position.coords.longitude}`,
        }
        AxiosGoogle().get('geocode/json',{params})
        .then(response => {
          resolve(response.data.results)
        })
        .catch(error => {
          reject(error);
        });
    })
};