import { gql } from '@apollo/client';

export const NEAR_QUERY = gql`
  query NearHealthCenters(
        $latitude: Float!, 
        $longitude: Float!, 
        $distance: Float!, 
        $size: Int!, 
        $page: Int!,
        $category: [String],
    ) {
    near(
      latitude: $latitude,
      longitude: $longitude,
      distance: $distance,
      size: $size,
      page: $page,
      category:$category
    ) {
      total
      hasMore
      healthCenters {
        id
        name
        category
      }
    }
  }
`;

export const WITHIN_QUERY = gql`
  query WithinHealthCenters(
        $latitude: Float!, 
        $longitude: Float!,
        $top_left_latitude: Float!,
        $top_left_longitude: Float!,
        $bottom_right_latitude: Float!,
        $bottom_right_longitude: Float!,
        $size: Int!, 
        $page: Int!,
        $category: [String],
    ) {
    within(
      latitude: $latitude,
      longitude: $longitude,
      topLeftLatitude: $top_left_latitude,
      topLeftLongitude: $top_left_longitude,
      bottomRightLatitude: $bottom_right_latitude,
      bottomRightLongitude: $bottom_right_longitude,
      page: $page,
      size: $size,
      category:$category
    ) {
      total
      hasMore
      healthCenters {
        id
        name
        category
      }
    }
  }
`;
export const CATEGORY_QUERY = gql`
    {
        categories {
            key
        }
    }
`;

export const HEALTHCENTER_QUERY = gql`
  query HealthCenter(
        $id: Int!
    ) {
      healthCenter(
        id:$id
      )
      {
        id
        name
        location{
          lat
          lon
        }
      }
  }
`;