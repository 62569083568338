export const createToken = (accessToken) => {
    return {
        type:'CREATETOKEN',
        accessToken: accessToken,
    }
};

export const userLocation = 
    (
        address, 
        area, 
        city, 
        lat, 
        lng, 
        top_left_latitude,
        top_left_longitude,
        bottom_right_latitude,
        bottom_right_longitude,
        searchType, 
        categories
    ) => {
    return {
        type:'USERLOCATION',
        address: address,
        area: area,
        city: city,
        lat: lat,
        lng: lng,
        top_left_latitude: top_left_latitude,
        top_left_longitude: top_left_longitude,
        bottom_right_latitude: bottom_right_latitude,
        bottom_right_longitude: bottom_right_longitude,
        searchType: searchType,
        categories: categories
    }
};

export const updateUserLocationCategories = (categories) => {
    return {
        type:'UPDATE_USERLOCATION_CATEGORIES',
        categories: categories
    }
};

export const locationLoading = (loading) => {
    return {
        type:'LOCATIONLOADING',
        loading: loading
    }
};

export const editLocation = (editLocation) => {
    return {
        type:'EDITLOCATION',
        editLocation: editLocation
    }
};

export const docxorApiLoading = (loading) => {
    return {
        type:'DOCXORAPILOADING',
        loading: loading
    }
};

export const sideBar = (sideBar) => {
    return {
        type:'SIDEBAR',
        sideBar: sideBar
    }
};

export const filters = (filters) => {
    return {
        type:'FILTERS',
        filters: filters
    }
};