import React, { useState } from 'react';
import Styled from 'styled-components';
import { LocationPinOutlinedIcon } from '../icons/DocxorIcon';
import {useSelector} from 'react-redux';

const SuggestionInputContainer = Styled.div`
    padding:6px;
    margin:0;
    width: 650px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background-color: #ffffff;
    border: ${props => props.$mode === 'focus' ? 'solid 1px rgba(26, 120, 209, 0.9)' : 'solid 1px rgba(26, 120, 209, 0.14)'};
    box-shadow: ${props => props.$mode === 'focus' ? '0 0 5px 0 rgba(26, 120, 209, 0.43)' : 'none'};
`;

const SearchBarContainer = Styled.div`
    padding:0;
    margin:0;
    display:flex;
    align-items:center;
`;

const StyledInput = Styled.input`
    height:50px;
    border:0;
    outline:0;
    width:100%;
    padding: 0 12px;
    border-radius: 15px;
    font-size: 1.1rem;
`;

const SuggestionContainer = Styled.div`
    border-top:solid 1px rgba(26, 120, 209, 0.14);
    padding:10px 0;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
`;

const StyledSugggestion = Styled.div`
    display:flex;
    align-items:center;
    width: 100%;
    height: 46px;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    &:hover {
        cursor:pointer;
        background-color:#f0f1f4;
        color:#1a78d1;
    }
`;

const IconContainer = Styled.div`
    padding:10px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledSugggestionText = Styled.div`
  padding:0;
`;

const StyledClear = Styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  color: ${({ theme }) => theme.primaryBlue};
  font-size: 14px;
  font-weight: 700;
`;

const SuggestionInput = (props) => {
    const [focus, setFocus] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [value, setValue] = useState("");
    const loading = useSelector(state => state.locationLoading.loading);  

    const handleInputFocus = () => {
        setFocus(true);
    }
    
    const handleInputBlur = () => {
        setFocus(false);
    }
    
    const hanleInputChange = (e) => {
       props.onSuggestionsFetchRequested(e.target.value)
       setValue(e.target.value)
    }
    
    const handleOnclickClear = () => {
        props.onSuggestionsClearRequested()
        setValue('');
    }
    
    const handleSuggestionClicked = (value) => {
        props.onSuggestionSelected(value)
        setValue(value.description);
        setFocus(false);
    }
    
    return (
        <SuggestionInputContainer $mode= {focus === true ? 'focus' : 'blur'} >
            <SearchBarContainer>
                    <StyledInput
                        placeholder={props.placeholder}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        onChange={hanleInputChange}
                        value = {value}
                        disabled={loading}
                    />
                    {value.length > 0 ? <StyledClear onClick={handleOnclickClear}>Clear</StyledClear> : props.EndInputAdornment ? props.EndInputAdornment : null}
            </SearchBarContainer>
            {
                props.suggestions && props.suggestions.length > 0 ? 
                    <SuggestionContainer>
                        {
                            props.suggestions.map((value, index) => (
                                <StyledSugggestion 
                                    key={index}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                    onClick={()=>handleSuggestionClicked(value)}
                                >
                                    <IconContainer>
                                        <LocationPinOutlinedIcon size = {18} color={hoveredIndex === index ? '#1a78d1' : "#515151"}/>
                                    </IconContainer>
                                    <StyledSugggestionText >{ value.description } </StyledSugggestionText>
                                </StyledSugggestion>
                            ))
                        }
                    </SuggestionContainer> 
                : null
            }
        </SuggestionInputContainer>
    )
}

export default SuggestionInput;