import React from 'react';
import Styled from 'styled-components';
import logo from '../asset/logo.svg'

const StyledLogoContainer= Styled.a`
    margin:0;
    padding:0;
    display:block;
    width:160px;
    height:100%;
    z-index:1;
`;

const StyledLogo = Styled.div`
    background: url(${logo});
    background-repeat: no-repeat;
    background-size: 160px;
    width: 160px;
    height: 100%;
    background-position: center;
`;


const Logo = () => {
    return(
        <StyledLogoContainer href = "/">
            <StyledLogo/>
        </StyledLogoContainer>
    );
};
export default Logo;