import store from "../store/store";
import { userLocation } from  '../store/action'

export const getSearchType = (details) => {
    switch (true) {
        case details.types.includes('country'):
            return 'na';
        case details.geometry && details.geometry.hasOwnProperty('bounds'):
            return 'within';
        default:
            return 'near';
    }
  };
  
  export const StructuredAddressDetails = async (details) => {
    try {
        const searchType = getSearchType(details[0]); 
        if (searchType === 'na') {
            return null
        }
        const location = details[0].geometry.location;

        const addressComponents = details[0].address_components;
        const city_detail = addressComponents.find(component =>
            component.types.includes('locality') || component.types.includes('administrative_area_level_1')
        );
        const area_detail = addressComponents.find(component =>
            component.types.includes('sublocality_level_1') || component.types.includes('sublocality_level_2') || component.types.includes('sublocality_level_3') || component.types.includes('sublocality_level_4')
        );
    
        let address = details[0].formatted_address;
        let area = area_detail ? area_detail.long_name : null;
        let city = city_detail ? city_detail.long_name : null;
        let lat = location.lat;
        let lng = location.lng;
        let top_left_latitude = details[0].geometry.bounds ? details[0].geometry.bounds.northeast.lat : null;
        let top_left_longitude = details[0].geometry.bounds ? details[0].geometry.bounds.southwest.lng : null;
        let bottom_right_latitude = details[0].geometry.bounds ? details[0].geometry.bounds.southwest.lat : null;
        let bottom_right_longitude = details[0].geometry.bounds ? details[0].geometry.bounds.northeast.lng : null;
        let categories = null;

        return { 
            address, 
            area, 
            city, 
            lat, 
            lng, 
            top_left_latitude,
            top_left_longitude,
            bottom_right_latitude,
            bottom_right_longitude,           
            searchType, 
            categories 
        };
        
    } catch (error) {
      console.error('Error extracting structured address details:', error);
      throw error;
    }
  };

export const addressSelected = (addressInfo) => {
    store.dispatch(
        userLocation(
            addressInfo.address, 
            addressInfo.area, 
            addressInfo.city, 
            addressInfo.lat, 
            addressInfo.lng, 
            addressInfo.top_left_latitude, 
            addressInfo.top_left_longitude, 
            addressInfo.bottom_right_latitude, 
            addressInfo.bottom_right_longitude, 
            addressInfo.searchType, 
            addressInfo.categories
        )
    );
    var doxr_userLocation = {
        "address":addressInfo.address, 
        "area":addressInfo.area, 
        "city":addressInfo.city, 
        "lat":addressInfo.lat, 
        "lng":addressInfo.lng, 
        "top_left_latitude":addressInfo.top_left_latitude, 
        "top_left_longitude":addressInfo.top_left_longitude, 
        "bottom_right_latitude":addressInfo.bottom_right_latitude, 
        "bottom_right_longitude":addressInfo.bottom_right_longitude, 
        "searchType":addressInfo.searchType, 
        "categories":addressInfo.categories
    };
    localStorage.setItem("doxr_userLocation", JSON.stringify(doxr_userLocation));
}