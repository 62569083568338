import React from 'react';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';

const GoogleMapComponent = ({ latitude, longitude }) => {
  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  const center = {
    lat: latitude,
    lng: longitude,
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_SECRET}>
      <GoogleMap 
        mapContainerStyle={containerStyle} 
        center={center} 
        zoom={16}       
        gestureHandling={'greedy'}
      >
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapComponent;
