import React from "react";
import SuggestionInput from "./SuggestionInput";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import LocateMe from "./LocateMe";
import { GooglePlaceDetails } from "../api/GoogleApi"
import { StructuredAddressDetails, addressSelected } from "../helpers/AddressHelper"
import store from "../store/store";
import { editLocation, locationLoading } from '../store/action'
import {useSelector} from 'react-redux';
import Styled from 'styled-components';

const LocateMeContainer = Styled.div`
    padding: 10px 0;
    margin: 0;
    width: 664px;
    display: flex;
    flex-direction: column;
`;

const EditLocation = () => {
  const {REACT_APP_GOOGLE_SECRET} = process.env;
  const [suggestions, setSuggestions] = React.useState([]);
  const isEditLocation = useSelector(state => state.editLocation);
  const [value, setValue] = React.useState("");

  const {
    // placesService, 
    placePredictions,
    getPlacePredictions,
    // isPlacePredictionsLoading, 
  } = usePlacesService({ apiKey: REACT_APP_GOOGLE_SECRET });

  React.useEffect(() => {
    if (placePredictions.length){
      setSuggestions(placePredictions)
    } 
  }, [placePredictions]);

  const onSuggestionsFetchRequested = async (value) => {
    setValue(value)
    var inputLength = value.length;
    if(inputLength > 2){
      getPlacePredictions({ input: value })
    }else{
      setSuggestions([]);
    }
  };
    
  const onSuggestionsClearRequested = () => {
    setSuggestions([]); 
    setValue('') 
  }
  
  const onSuggestionSelected = async (suggestion) => {  
    try {
      setSuggestions([]);
      const placeDetails = await GooglePlaceDetails(suggestion.place_id);
      const addressInfo= await StructuredAddressDetails(placeDetails);
      if (addressInfo != null) {
        store.dispatch(editLocation(!isEditLocation.editLocation));
        addressSelected(addressInfo)
      } else {
        alert("choose a city or a place")
      }
    } catch (error) {
        console.error('Error in onSuggestionSelected:', error);
    }
  };

  const onFetchCoordsInfo = () => {
    store.dispatch(locationLoading(false));
    store.dispatch(editLocation(!isEditLocation.editLocation));
  }

  return (
    <React.Fragment>
        <SuggestionInput
            placeholder = {"Search for City, Area, Street, Landmark"}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
        />   
        {
            value.length > 0 ? null 
            : 
            <LocateMeContainer>
                <LocateMe 
                    title={"Get My Current Location Using GPS"} 
                    btnType = {'editLocation'}
                    onFetchCoordsInfo = {onFetchCoordsInfo}
                />
            </LocateMeContainer> 
        }
    </React.Fragment>
        
  )
}

export default EditLocation;