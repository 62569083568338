var doxr_userLocation = JSON.parse(localStorage.getItem("doxr_userLocation"));

const initialState = {
    address: doxr_userLocation ? doxr_userLocation.address: null,
    area: doxr_userLocation ? doxr_userLocation.area: null,
    city:doxr_userLocation ? doxr_userLocation.city: null,
    lat: doxr_userLocation? doxr_userLocation.lat: null,
    lng: doxr_userLocation? doxr_userLocation.lng: null,
    top_left_latitude: doxr_userLocation? doxr_userLocation.top_left_latitude: null,
    top_left_longitude: doxr_userLocation? doxr_userLocation.top_left_longitude: null,
    bottom_right_latitude: doxr_userLocation? doxr_userLocation.bottom_right_latitude: null,
    bottom_right_longitude: doxr_userLocation? doxr_userLocation.bottom_right_longitude: null,
    searchType: doxr_userLocation ? doxr_userLocation.searchType: null,
    categories: doxr_userLocation ? doxr_userLocation.categories: null,
}

const UserLocationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'USERLOCATION':
            return Object.assign( {}, state,{
                address: action.address,
                area: action.area,
                city: action.city,
                lat: action.lat,
                lng: action.lng,
                top_left_latitude: action.top_left_latitude,
                top_left_longitude: action.top_left_longitude,
                bottom_right_latitude: action.bottom_right_latitude,
                bottom_right_longitude: action.bottom_right_longitude,
                searchType: action.searchType,
                categories: action.categories,
            });
        case 'UPDATE_USERLOCATION_CATEGORIES':
            return {
                ...state,
                categories: action.categories,
            };
        default:
            return state;
    }
}
export default UserLocationReducer;
