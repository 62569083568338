import React from "react";
import SuggestionInput from "./SuggestionInput";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import LocateMe from "./LocateMe";
import { GooglePlaceDetails } from "../api/GoogleApi"
import { StructuredAddressDetails, addressSelected } from "../helpers/AddressHelper"
import store from "../store/store";
import {locationLoading } from '../store/action'

const SearchLocation = () => {
  const {REACT_APP_GOOGLE_SECRET} = process.env;
  const [suggestions, setSuggestions] = React.useState([]);

  const {
    // placesService, 
    placePredictions,
    getPlacePredictions,
    // isPlacePredictionsLoading, 
  } = usePlacesService({ apiKey: REACT_APP_GOOGLE_SECRET });

  React.useEffect(() => {
    if (placePredictions.length){
      setSuggestions(placePredictions)
    } 
  }, [placePredictions]);

  const onSuggestionsFetchRequested = async (value) => {
    var inputLength = value.length;
    if(inputLength > 2){
      getPlacePredictions({ input: value })
    }else{
      setSuggestions([]);
    }
  };
    
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);  
  }
  
  const onSuggestionSelected = async (suggestion) => {  
    try {
      setSuggestions([]);
      const placeDetails = await GooglePlaceDetails(suggestion.place_id);
      const addressInfo= await StructuredAddressDetails(placeDetails);
      if (addressInfo != null) {
        addressSelected(addressInfo)
      } else {
        alert("choose a city or a place")
      }
    } catch (error) {
        console.error('Error in onSuggestionSelected:', error);
    }
  };
  const onFetchCoordsInfo = () => {
    store.dispatch(locationLoading(false));
  }
  return (
      <SuggestionInput
        placeholder = {"Enter your location"}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={onSuggestionSelected}
        EndInputAdornment = {<LocateMe title={"Locate Me"} onFetchCoordsInfo = {onFetchCoordsInfo}/>}
      />      
  )
}

export default SearchLocation;