import {createGlobalStyle} from 'styled-components';
export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        padding:0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }
    input[type="search"]{
        -webkit-appearance: textfield;
    }
    input[type="search"]::-webkit-search-cancel-button{
        -webkit-appearance: none;
        -moz-appearance:none;
      }
    .overlay {
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #282c3f;
        opacity: .7;
        overflow: hidden;
    }
    .overlay-enabled{
        overflow: hidden;
        margin-right:15px;
    }
`;

export const LIGHT = {
    primaryWhite:'#FFFFFF',
    secondaryWhite: '#fafafa',
    primaryGreen:'#34a853',
    primaryBlue:'#1777d1',
    primaryRed:'#cb0701',
    primaryPurple:'#6a0499',
    primaryOrange:'#FB9901',
    primaryGrey:'#242424',
    secondaryGrey:'#363636'
};