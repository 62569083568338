import React from 'react';
import HealthServices from './HealthServices'
import Styled from 'styled-components';

const StyledAdd = Styled.div`
  display:flex;
  justify-content:center;
  height:76px;
`;

const Styledtitle = Styled.h1`
  margin:0;
  font-size: 4rem;
  font-weight: 600;
  letter-spacing: 1px;
  padding-right:10px;
  color:#363636;
`;

const StyledNearMe = Styled.h2`
  margin:0;
  font-size: 4rem;
  font-weight: 600;
  letter-spacing: 1px;
  color:#363636;
  margin-bottom:30px;
  text-align: center;
`;


const CoverText = () => {  
    return(
        <React.Fragment>
            <StyledAdd>
                <Styledtitle>Find </Styledtitle>
                <HealthServices/>
             </StyledAdd>
            <StyledNearMe>near your location</StyledNearMe>
       </React.Fragment>
    );
};
export default CoverText;