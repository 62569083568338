import axios from 'axios';
import store from '../../store/store';
import {docxorApiLoading} from '../../store/action';

const {REACT_APP_GOOGLE_API_URL} = process.env;
const {REACT_APP_GOOGLE_SECRET} = process.env

const AxiosGoogle = () => {
    const instance = axios.create({
        baseURL: REACT_APP_GOOGLE_API_URL
    });
    
    instance.interceptors.request.use(function (config) {
        // Do something before request is sent
        store.dispatch(docxorApiLoading(true));
        config.params = {
            ...config.params,
            key: REACT_APP_GOOGLE_SECRET // Replace 'key' with the parameter name your API expects
        };
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    instance.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    });

    return instance;
}

export default AxiosGoogle;